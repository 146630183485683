import React from 'react'
import { FiBox, FiTag, FaBarcode, FiUsers, FiTruck, FiLogOut } from 'react-icons/all'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { logout } from 'services/auth'

const Page = ({ children, history, location }) => {
  return (
    <div className="page">
      <div className="page-main">
        <div className="header py-2">
          <div className="container">
            <div className="d-flex">
              <div className="logo">
                <div className="img">
                  <img
                    src={require('assets/images/logo.png')}
                    alt=""
                    className="header-brand-img"
                  />
                </div>
                <div className="title">
                  <b>Gelmeza</b>
                  <span>Sistema de Custos</span>
                </div>
              </div>
              <div className="d-flex order-lg-2 ml-auto">
                <div className="welcome"></div>
                <button onClick={() => logout(history)} className="logout">
                  <FiLogOut />
                  <span>Sair</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="header d-lg-flex p-0 collapse">
          <div className="container">
            <div className="row">
              <div className="col">
                <ul className="nav nav-tabs border-0 flex-column flex-lg-row">
                  <li className="nav-item">
                    <Link
                      className={location.pathname.includes('/simple-products') ? 'active' : ''}
                      to="/simple-products"
                    >
                      <FiBox />
                      Produtos Simples
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={location.pathname.includes('/compound-products') ? 'active' : ''}
                      to="/compound-products"
                    >
                      <FiBox />
                      Produtos Compostos
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={location.pathname.includes('/categories') ? 'active' : ''}
                      to="/categories"
                    >
                      <FiTag />
                      Categorias
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={location.pathname.includes('/ncms') ? 'active' : ''}
                      to="/ncms"
                    >
                      <FaBarcode />
                      NCM
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={location.pathname.includes('/employees') ? 'active' : ''}
                      to="/employees"
                    >
                      <FiUsers />
                      Funcionários
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={location.pathname.includes('/providers') ? 'active' : ''}
                      to="/providers"
                    >
                      <FiTruck />
                      Fornecedores
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="page-content">{children}</div>
      </div>
      <div className="page-footer">
        <img src={require('assets/images/footer-logo.png')} alt="" />
      </div>
    </div>
  )
}

export default withRouter(Page)
