import { useCallback } from 'react'
import api from 'services/api'

const useRemoveSimpleProducts = () => {
  return useCallback(async (id) => {
    return api.delete(`/simple-products/${id}`)
  }, [])
}

export default useRemoveSimpleProducts
