import React from 'react'
import { FiEdit } from 'react-icons/all'
import { Link } from 'react-router-dom'

const EditButton = ({ ...props }) => {
  return (
    <Link className="button-action button-edit" {...props}>
      <FiEdit />
    </Link>
  )
}

export default EditButton
