import React from 'react'
import './Loader.scss'

const Loader = ({ absolute = false, size = 'regular' }) => {
  return (
    <div className={`loader ${size} ${absolute ? 'absolute' : ''}`}>
      <div className="indicator" />
    </div>
  )
}

export default Loader
