import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import Input from 'components/shared/Input'
import Button from 'components/shared/Button'

const Filter = ({ handleFilters, initialValues }) => {
  const { register, handleSubmit } = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
  })

  const onSubmit = (data) => {
    handleFilters({ ...data, page: 1 })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <Input ref={register} name="description" label="NCM" />
        <Button type="submit" label="Filtrar" col={1} fullWidth />
      </div>
    </form>
  )
}

export default Filter
