import { useCallback } from 'react'
import api from 'services/api'

const useGetNcmsById = () => {
  return useCallback(async (id) => {
    return api.get(`/ncms/single/${id}`)
  }, [])
}

export default useGetNcmsById
