import { useCallback } from 'react'
import api from 'services/api'

const useRemoveEmployees = () => {
  return useCallback(async (id) => {
    return api.delete(`/employees/${id}`)
  }, [])
}

export default useRemoveEmployees
