const currencyOptions = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
}).resolvedOptions()

export function numberToReal(value, prefix = true) {
  return parseFloat(value).toLocaleString('pt-BR', {
    ...currencyOptions,
    style: prefix ? 'currency' : 'decimal',
  })
}

export function coefficient(num) {
  return 1 - num / 100
}

export function calculateMarginTotal(percentage, amount) {
  return (percentage / 100) * amount + amount
}

export function roundDecimal(amount) {
  return Math.round((amount + Number.EPSILON) * 100) / 100
}

export function percentage(num, per) {
  return (num / 100) * per
}

export const round = (num, decimalPlaces = 0) => {
  num = Math.round(Math.abs(num) + 'e' + decimalPlaces) * Math.sign(num)
  return Number(num + 'e' + -decimalPlaces)
}
