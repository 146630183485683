import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { isAuthenticated } from 'services/auth'
import Loader from '../Loader'
import Page from 'components/template/Page'
import { DataProvider } from 'context/data'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [isLogged, setIsLogged] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const checkUser = async () => {
      const loggedIn = await isAuthenticated()
      console.log('loggedIn', loggedIn)
      setIsLogged(loggedIn)
      setIsLoading(false)
    }

    checkUser()
  }, [])

  return isLoading ? (
    <Loader />
  ) : (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          <DataProvider>
            <Page>
              <Component {...props} />
            </Page>
          </DataProvider>
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute
