import { useCallback } from 'react'
import api from 'services/api'

const useRemoveNcms = () => {
  return useCallback(async (id) => {
    return api.delete(`/ncms/${id}`)
  }, [])
}

export default useRemoveNcms
