import React from 'react'
import './Divider.scss'

const Divider = ({ title = null, withBorder = true }) => {
  return (
    <div className={`divider ${withBorder ? 'with-border' : ''} ${title ? 'with-title' : ''}`}>
      {title && <span>{title}</span>}
    </div>
  )
}

export default Divider
