import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import Container from 'components/template/Container'
import Card from 'components/shared/Card'
import Input from 'components/shared/Input'
import Select from 'components/shared/Select'
import Divider from 'components/shared/Divider'
import Button from 'components/shared/Button'
import useRegisterProviders from 'hooks/providers/useRegisterProviders'
import useGetProvidersById from 'hooks/providers/useGetProvidersById'

import { toast } from 'react-toastify'
import Loader from 'components/shared/Loader'
import { useData } from 'context/data'
import useGetProviders from 'hooks/providers/useGetProviders'

const ProvidersRegister = ({ history, match }) => {
  const { setProviders } = useData()

  const [type, setType] = useState('PJ')
  const [formValues, setFormValues] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [providerId, setProviderId] = useState(null)

  const doGetProvidersById = useGetProvidersById()
  const doRegisterProviders = useRegisterProviders()
  const doGetProviders = useGetProviders()

  const { register, control, handleSubmit, errors, watch, setValue } = useForm({
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => formValues, [formValues]),
  })

  const fetchProviderData = useCallback(async () => {
    const provider = await doGetProvidersById(match.params.id)
    setProviderId(provider.data.id)
    setFormValues({ ...provider.data })
    setIsLoading(false)
  }, [doGetProvidersById, match.params.id])

  useEffect(() => {
    setType(watch('type'))
  }, [watch('type')]) // eslint-disable-line

  useEffect(() => {
    if (match.params.id) {
      fetchProviderData()
    } else {
      setIsLoading(false)
    }
  }, [fetchProviderData, match.params.id])

  const onSubmit = async (data) => {
    try {
      await doRegisterProviders({ ...data, id: providerId })
      await doGetProviders().then((result) => {
        setProviders(result.data)
      })
      toast.success('Fornecedor cadastrado com sucesso')
      history.push('/providers')
    } catch {
      toast.error('Ocorreu um erro, tente novamente!')
    }
  }

  return (
    <Container title="Cadastro de Fornecedor">
      <Card>
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Select
              col={4}
              setValue={setValue}
              register={register}
              label="Tipo de cadastro"
              name="type"
              options={[
                {
                  value: 'PJ',
                  label: 'Pessoa Jurídica',
                },
                {
                  value: 'PF',
                  label: 'Pessoa Física',
                },
              ]}
              placeholder="Selecione um fornecedor"
              error={errors}
              defaultValue={formValues.type || 'PJ'}
            />
            <Input
              label="Apelido"
              name="nickname"
              placeholder="Informe um apelido(nome reduzido) para esse fornecedor"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.nickname}
            />
            <Divider />
            {type === 'PJ' ? (
              <>
                <Input
                  label="Razão Social"
                  name="company_name"
                  placeholder="Informe a Razão Social"
                  col={6}
                  ref={register({ required: true })}
                  error={errors}
                  defaultValue={formValues.company_name}
                />
                <Input
                  label="Nome Fantasia"
                  name="fantasy_name"
                  placeholder="Informe o Nome Fantasia"
                  col={6}
                  ref={register}
                  error={errors}
                  defaultValue={formValues.fantasy_name}
                />
                <Input
                  label="CNPJ"
                  mask="999.999.999/9999-99"
                  name="cnpj"
                  placeholder="Informe o CNPJ"
                  col={3}
                  ref={register({ required: true })}
                  error={errors}
                  defaultValue={formValues.cnpj}
                  control={control}
                  setValue={setValue}
                />
                <Input
                  label="Inscrição estadual"
                  name="state_registration"
                  placeholder="Informe a Inscrição estadual"
                  col={3}
                  ref={register}
                  error={errors}
                  defaultValue={formValues.state_registration}
                />
                <Input
                  label="Inscrição municipal"
                  name="municipal_registration"
                  placeholder="Informe a Inscrição municipal"
                  col={3}
                  ref={register}
                  error={errors}
                  defaultValue={formValues.municipal_registration}
                />
                <Select
                  col={3}
                  setValue={setValue}
                  register={register}
                  label="Contribuição ICMS"
                  name="icms"
                  options={[
                    {
                      value: 'taxpayer',
                      label: 'Contribuinte',
                    },
                    {
                      value: 'non-contributor',
                      label: 'Não Contribuinte',
                    },
                  ]}
                  placeholder="É contribuinte?"
                  error={errors}
                  defaultValue={formValues.icms}
                  isCleareable
                />
              </>
            ) : (
              <>
                <Input
                  label="Nome"
                  name="name"
                  placeholder="Informe o nome"
                  col={6}
                  ref={register({ required: true })}
                  error={errors}
                  defaultValue={formValues.name}
                />

                <Input
                  label="CPF"
                  mask="999.999.999-99"
                  name="cpf"
                  placeholder="Informe o CPF"
                  col={3}
                  ref={register}
                  error={errors}
                  defaultValue={formValues.cpf}
                  control={control}
                  setValue={setValue}
                />
                <Input
                  label="RG"
                  name="rg"
                  placeholder="Informe o RG"
                  col={3}
                  ref={register}
                  error={errors}
                  defaultValue={formValues.rg}
                />
              </>
            )}
            <Divider title="Informações de contato" />
            <Input
              label="Telefone"
              name="phone"
              placeholder="Informe o telefone"
              col={4}
              ref={register}
              error={errors}
              defaultValue={formValues.phone}
            />
            <Input
              label="E-mail"
              name="email"
              placeholder="Informe o e-mail"
              col={4}
              ref={register}
              error={errors}
              defaultValue={formValues.email}
              type="email"
            />
            <Input
              label="Responsável"
              name="contact_name"
              placeholder="Informe o nome do responsável"
              col={4}
              ref={register}
              error={errors}
              defaultValue={formValues.contact_name}
            />

            <Divider title="Endereço" />

            <Input
              label="Endereço"
              name="address"
              placeholder="Informe o endereço"
              col={6}
              ref={register}
              error={errors}
              defaultValue={formValues.address}
            />
            <Input
              label="Número"
              name="address_number"
              placeholder="Informe o número"
              col={2}
              ref={register}
              error={errors}
              defaultValue={formValues.address_number}
            />
            <Input
              label="Complemento"
              name="address_complement"
              placeholder="Informe o complemento"
              col={2}
              ref={register}
              error={errors}
              defaultValue={formValues.address_complement}
            />
            <Input
              label="Bairro"
              name="neighborhood"
              placeholder="Informe o bairro"
              col={2}
              ref={register}
              error={errors}
              defaultValue={formValues.neighborhood}
            />
            <Input
              label="Cidade"
              name="city"
              placeholder="Informe a cidade"
              col={4}
              ref={register}
              error={errors}
              defaultValue={formValues.city}
            />
            <Input
              label="Estado"
              name="state"
              placeholder="Informe o estado"
              col={4}
              ref={register}
              error={errors}
              defaultValue={formValues.state}
            />
            <Input
              label="CEP"
              name="zipcode"
              placeholder="Informe CEP"
              col={4}
              ref={register}
              error={errors}
              defaultValue={formValues.zipcode}
              mask="99999-999"
              control={control}
              setValue={setValue}
            />

            <Divider withBorder />
            <div className="actions">
              <Button to="/providers" label="Cancelar" variation="btn-secondary" />
              <Button type="submit" label="Salvar" variation="btn-primary" />
            </div>
          </form>
        )}
      </Card>
    </Container>
  )
}

export default ProvidersRegister
