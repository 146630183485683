import React, { useState, useEffect, useCallback } from 'react'
import Container from 'components/template/Container'
import Button from 'components/shared/Button'
import { FiPlus } from 'react-icons/all'
import Table from 'components/shared/Table'
import Paginator from 'components/shared/Paginator'
import Modal from 'components/shared/Modal'
import Loader from 'components/shared/Loader'
import EditButton from 'components/shared/Table/components/EditButton'
import RemoveButton from 'components/shared/Table/components/RemoveButton'
import { toast } from 'react-toastify'
import Filter from './components/Filter'
import useGetEmployees from 'hooks/employees/useGetEmployees'
import useRemoveEmployees from 'hooks/employees/useRemoveEmployees'
import { numberToReal } from '../../utils/functions'

const INITIAL_FILTER_VALUES = {
  name: '',
  page: 1,
}

const Employees = () => {
  const doGetEmployees = useGetEmployees()
  const doRemoveEmployees = useRemoveEmployees()

  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({
    totalItems: 0,
    lastPage: 0,
    currentPage: 0,
  })
  const [removeItem, setRemoveItem] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [queryParams, setQueryParams] = useState(INITIAL_FILTER_VALUES)

  const getItems = useCallback(async () => {
    setIsLoading(true)
    doGetEmployees({ ...queryParams })
      .then((result) => {
        const { data, total, lastPage, currentPage } = result.data
        setPagination({
          totalItems: total,
          lastPage: lastPage,
          currentPage: currentPage,
        })
        setItems(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [doGetEmployees, queryParams])

  useEffect(() => {
    getItems()
  }, [queryParams, getItems])

  const handlePagination = (page) => {
    setQueryParams({ ...queryParams, page })
  }

  const handleRemoveItem = async () => {
    if (removeItem) {
      try {
        await doRemoveEmployees(removeItem.id)
        setItems(items.filter((item) => item.id !== removeItem.id))
        setRemoveItem(null)
        toast.success('Funcionário removido com sucesso!')
      } catch {
        toast.error('Ocorreu um erro!')
      }
    }
  }

  return (
    <Container
      title="Funcionários"
      action={
        <Button
          to="/employees/register"
          label="Adicionar funcionário"
          icon={<FiPlus />}
          col="col"
        />
      }
    >
      <Table
        header={<Filter initialValues={INITIAL_FILTER_VALUES} handleFilters={setQueryParams} />}
      >
        <thead>
          <tr>
            <th>Cód.</th>
            <th>Nome</th>
            <th>Salário</th>
            <th>Medida</th>
            <th>Total trabalhado</th>
            <th>Valor total</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={9} className="loading">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {items.length > 0 ? (
                items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.code}</td>
                    <td>{item.name}</td>
                    <td>{numberToReal(item.earnings)}</td>
                    <td>{item.measure}</td>
                    <td>{item.total}</td>
                    <td>{numberToReal(item.total_cost)}</td>
                    <td>
                      <EditButton to={`/employees/register/${item.id}`} />
                      <RemoveButton onClick={() => setRemoveItem(item)} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="empty" colSpan={9}>
                    Nenhum funcionário encontrado
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>

      <Paginator props={pagination} handlePagination={handlePagination} />
      {removeItem && (
        <Modal
          title="Remover"
          handleCloseModal={() => setRemoveItem(null)}
          footer={
            <div className="modal-actions">
              <button
                onClick={() => setRemoveItem(null)}
                type="button"
                className="btn btn-secondary"
              >
                Cancelar
              </button>
              <button onClick={handleRemoveItem} type="button" className="btn btn-danger">
                Remover
              </button>
            </div>
          }
        >
          <p>Tem certeza que deseja remover esse funcionário?</p>
        </Modal>
      )}
    </Container>
  )
}

export default Employees
