import { useCallback } from 'react'
import api from 'services/api'

const useGetCategoriesById = () => {
  return useCallback(async (id) => {
    return api.get(`/categories/single/${id}`)
  }, [])
}

export default useGetCategoriesById
