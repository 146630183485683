import { useCallback } from 'react'
import api from 'services/api'

const useRegisterProviders = () => {
  return useCallback(async (values) => {
    const id = values.id ? values.id : ''
    const method = id ? 'put' : 'post'

    return api[method](`/providers/upsert/${id}`, values)
  }, [])
}

export default useRegisterProviders
