import { useCallback } from 'react'
import api from 'services/api'

const useRemoveCategories = () => {
  return useCallback(async (id) => {
    return api.delete(`/categories/${id}`)
  }, [])
}

export default useRemoveCategories
