import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import Container from 'components/template/Container'
import Card from 'components/shared/Card'
import Input from 'components/shared/Input'
import Select from 'components/shared/Select'
import Divider from 'components/shared/Divider'
import Button from 'components/shared/Button'
import useRegisterEmployees from 'hooks/employees/useRegisterEmployees'
import useGetEmployeesById from 'hooks/employees/useGetEmployeesById'

import { toast } from 'react-toastify'
import Loader from 'components/shared/Loader'

const EmployeesRegister = ({ history, match }) => {
  const [formValues, setFormValues] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [employeeId, setEmployeeId] = useState(null)

  const doGetEmployeesById = useGetEmployeesById()
  const doRegisterEmployees = useRegisterEmployees()

  const { register, control, handleSubmit, errors, watch, setValue } = useForm({
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => formValues, [formValues]),
  })

  const fetchEmployeeData = useCallback(async () => {
    const employee = await doGetEmployeesById(match.params.id)
    setEmployeeId(employee.data.id)
    setFormValues({ ...employee.data })
    setIsLoading(false)
  }, [doGetEmployeesById, match.params.id])

  useEffect(() => {
    const earnings = parseFloat(watch('earnings')) || 0
    const total = parseFloat(watch('total')) || 1
    setValue('total_cost', parseFloat(earnings / total))
  }, [watch('earnings'), watch('total')]) // eslint-disable-line

  useEffect(() => {
    if (match.params.id) {
      fetchEmployeeData()
    } else {
      setIsLoading(false)
    }
  }, [fetchEmployeeData, match.params.id])

  const onSubmit = async (data) => {
    try {
      await doRegisterEmployees({ ...data, id: employeeId })
      toast.success('Funcionário cadastrado com sucesso')
      history.push('/employees')
    } catch (e) {
      toast.error('Ocorreu um erro, tente novamente!')
    }
  }

  return (
    <Container title="Cadastro de Funcionário">
      <Card>
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Input
              label="Código"
              name="code"
              placeholder="Informe o código do funcionário"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.code}
              col={4}
            />
            <Input
              label="Nome"
              name="name"
              placeholder="Informe o nome"
              col={8}
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.name}
            />
            <Input
              control={control}
              mask="money"
              label="Salário + Encargos"
              name="earnings"
              col={3}
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.earnings}
            />
            <Select
              col={3}
              setValue={setValue}
              register={register}
              label="Medida"
              name="measure"
              options={[
                {
                  value: 'HORAS',
                  label: 'HORAS',
                },
                {
                  value: 'LTS',
                  label: 'LTS',
                },
              ]}
              placeholder="Selecione o tipo da medida"
              error={errors}
              defaultValue={formValues.measure}
              isCleareable
            />

            <Input
              label="Total trabalhado"
              name="total"
              type="number"
              col={3}
              ref={register({ required: true })}
              error={errors}
              placeholder="Informe o total trabalhado"
              defaultValue={formValues.total}
            />

            <Input
              readOnly
              control={control}
              mask="money"
              label="Valor Total"
              name="total_cost"
              col={3}
              ref={register}
              error={errors}
              defaultValue={formValues.total_cost}
            />
            <Divider withBorder />
            <div className="actions">
              <Button to="/employees" label="Cancelar" variation="btn-secondary" />
              <Button type="submit" label="Salvar" variation="btn-primary" />
            </div>
          </form>
        )}
      </Card>
    </Container>
  )
}

export default EmployeesRegister
