import { useCallback } from 'react'
import api from 'services/api'

const useGetCompoundProductsById = () => {
  return useCallback(async (id) => {
    return api.get(`/compound-products/single/${id}`)
  }, [])
}

export default useGetCompoundProductsById
