import React, { useState, useEffect, useCallback } from 'react'
import Container from 'components/template/Container'
import Button from 'components/shared/Button'
import { FiPlus } from 'react-icons/all'
import Table from 'components/shared/Table'
import Paginator from 'components/shared/Paginator'
import Modal from 'components/shared/Modal'
import Loader from 'components/shared/Loader'
import EditButton from 'components/shared/Table/components/EditButton'
import RemoveButton from 'components/shared/Table/components/RemoveButton'
import { toast } from 'react-toastify'
import Filter from './components/Filter'
import useGetProviders from 'hooks/providers/useGetProviders'
import useRemoveProviders from 'hooks/providers/useRemoveProviders'

const INITIAL_FILTER_VALUES = {
  provider: '',
  page: 1,
}

const Providers = () => {
  const doGetProviders = useGetProviders()
  const doRemoveProviders = useRemoveProviders()
  const [items, setItems] = useState([])
  const [pagination, setPagination] = useState({
    totalItems: 0,
    lastPage: 0,
    currentPage: 0,
  })
  const [removeItem, setRemoveItem] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [queryParams, setQueryParams] = useState(INITIAL_FILTER_VALUES)

  const getItems = useCallback(async () => {
    setIsLoading(true)
    doGetProviders({ ...queryParams })
      .then((result) => {
        const { data, total, lastPage, currentPage } = result.data
        setPagination({
          totalItems: total,
          lastPage: lastPage,
          currentPage: currentPage,
        })
        setItems(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [queryParams, doGetProviders])

  useEffect(() => {
    getItems()
  }, [queryParams, getItems])

  const handlePagination = (page) => {
    setQueryParams({ ...queryParams, page })
  }

  const handleRemoveItem = async () => {
    if (removeItem) {
      try {
        await doRemoveProviders(removeItem.id)
        setItems(items.filter((provider) => provider.id !== removeItem.id))
        setRemoveItem(null)
        toast.success('Fornecedor removido com sucesso!')
      } catch {
        toast.error('Ocorreu um erro!')
      }
    }
  }

  return (
    <Container
      title="Fornecedores"
      action={
        <Button to="/providers/register" label="Adicionar fornecedor" icon={<FiPlus />} col="col" />
      }
    >
      <Table
        header={<Filter initialValues={INITIAL_FILTER_VALUES} handleFilters={setQueryParams} />}
      >
        <thead>
          <tr>
            <th>Fornecedor(Apelido)</th>
            <th>Tipo de cadastro</th>
            <th>Razão Social / Nome</th>
            <th>E-mail</th>
            <th>Telefone</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={9} className="loading">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {items.length > 0 ? (
                items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.nickname}</td>
                    <td>{item.type === 'PJ' ? 'Pessoa Jurídica' : 'Pessoa Física'}</td>
                    <td>{item.type === 'PJ' ? item.company_name : item.name}</td>
                    <td>{item.email || '--'}</td>
                    <td>{item.phone || '--'}</td>
                    <td>
                      <EditButton to={`/providers/register/${item.id}`} />
                      <RemoveButton onClick={() => setRemoveItem(item)} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="empty" colSpan={9}>
                    Nenhum fornecedor encontrado
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>

      <Paginator props={pagination} handlePagination={handlePagination} />
      {removeItem && (
        <Modal
          title="Remover"
          handleCloseModal={() => setRemoveItem(null)}
          footer={
            <div className="modal-actions">
              <button
                onClick={() => setRemoveItem(null)}
                type="button"
                className="btn btn-secondary"
              >
                Cancelar
              </button>
              <button onClick={handleRemoveItem} type="button" className="btn btn-danger">
                Remover
              </button>
            </div>
          }
        >
          <p>Tem certeza que deseja remover esse fornecedor?</p>
        </Modal>
      )}
    </Container>
  )
}

export default Providers
