import React from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import cn from 'classnames'

import api from 'services/api'
import { login } from 'services/auth'

import logo from 'assets/images/logo.png'
import './SignIn.scss'

const SignIn = ({ history }) => {
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = async (data) => {
    try {
      const response = await api.post('/auth/signin', { ...data })
      login(response.data)
      history.push('/simple-products')
    } catch (err) {
      toast.error('E-mail ou senha inválidos')
      console.log(err)
    }
  }
  console.log(errors)
  return (
    <div className="login-page">
      <div className="container">
        <div className="row">
          <div className="mx-auto col col-login">
            <div className="mb-6 text-center logo">
              <div className="img">
                <img src={logo} alt="logo" />
              </div>
              <b>Gelmeza</b>
              <span>Sistema de Custos</span>
            </div>
            <form onSubmit={handleSubmit(onSubmit)} className="card" autoComplete="off">
              <div className="p-6 card-body">
                <div className="card-title">Acessar</div>
                <div className="form-group">
                  <label className="form-label">E-mail</label>
                  <input
                    name="email"
                    className={cn('form-control', {
                      error: errors.email,
                    })}
                    type="text"
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  {errors.email && <span className="error">Campo obrigatório</span>}
                </div>
                <div className="form-group">
                  <label className="form-label">Senha</label>
                  <input
                    name="password"
                    className={cn('form-control', {
                      error: errors.password,
                    })}
                    type="password"
                    autoComplete="off"
                    ref={register({ required: true })}
                  />
                  {errors.password && <span className="error">Campo obrigatório</span>}
                </div>
                <div className="form-footer">
                  <button className="btn btn-block btn-primary" type="submit">
                    Login
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn
