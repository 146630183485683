import React, { createContext, useContext, useState, useEffect, useCallback } from 'react'
import useGetCategories from '../hooks/categories/useGetCategories'
import useGetProviders from '../hooks/providers/useGetProviders'
import useGetNcms from '../hooks/ncms/useGetNcms'
import Loader from '../components/shared/Loader'

export const DataContext = createContext()
export const useData = () => useContext(DataContext)

export const DataProvider = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [categories, setCategories] = useState([])
  const [providers, setProviders] = useState([])
  const [ncms, setNcms] = useState([])

  const doGetCategories = useGetCategories()
  const doGetProviders = useGetProviders()
  const doGetNcms = useGetNcms()

  const fetchInitialData = useCallback(() => {
    Promise.all([
      doGetCategories().then((result) => {
        setCategories(result.data)
      }),
      doGetProviders().then((result) => {
        setProviders(result.data)
      }),
      doGetNcms().then((result) => {
        setNcms(result.data)
      }),
    ]).then(() => setIsLoading(false))
  }, [doGetCategories, doGetProviders, doGetNcms])

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  return isLoading ? (
    <Loader />
  ) : (
    <DataContext.Provider
      value={{
        categories,
        setCategories,
        providers,
        setProviders,
        ncms,
        setNcms,
      }}
      {...props}
    />
  )
}
