import { useCallback } from 'react'
import api from 'services/api'

const useGetProvidersById = () => {
  return useCallback(async (id) => {
    return api.get(`/providers/single/${id}`)
  }, [])
}

export default useGetProvidersById
