import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import Container from 'components/template/Container'
import Card from 'components/shared/Card'
import Input from 'components/shared/Input'
import InputWithSymbol from 'components/shared/InputWithSymbol'
import Select from 'components/shared/Select'
import Divider from 'components/shared/Divider'
import Button from 'components/shared/Button'
import useRegisterSimpleProducts from 'hooks/simpleProducts/useRegisterSimpleProducts'
import useGetSimpleProductsById from 'hooks/simpleProducts/useGetSimpleProductsById'
import { roundDecimal } from 'utils/functions'

import { useData } from 'context/data'
import { toast } from 'react-toastify'
import Loader from 'components/shared/Loader'

const SimpleProductsRegister = ({ history, match }) => {
  const [formValues, setFormValues] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [productId, setProductId] = useState(null)
  const [ipiReadOnly, setIpiReadOnly] = useState(false)

  const { categories, providers, ncms } = useData()

  const doGetSimpleProductsById = useGetSimpleProductsById()
  const doRegisterSimpleProducts = useRegisterSimpleProducts()

  const { register, control, handleSubmit, errors, watch, setValue, getValues } = useForm({
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => formValues, [formValues]),
  })

  const watchTotalCost = watch('total_cost')
  const watchResaleMargin = watch('resale_margin')
  const watchIpiSell = watch('ipi_sell')
  const watchDistributorMargin = watch('distributor_margin')
  const watchProducerMargin = watch('producer_margin')
  const watchIcmsSell = watch('icms_sell')
  const watchPisConfins = watch('pis_confins')
  const watchExpenses = watch('expenses')
  const watchCommission = watch('commission')

  const watchConversion = watch('conversion')
  const watchPrice = watch('price')
  const watchFreight = watch('freight')
  const watchIpi = watch('ipi')
  const watchSt = watch('st')
  const watchIcmsBuy = watch('icms_buy')
  const watchNcm = watch('ncm')

  const fetchProductData = useCallback(async () => {
    const product = await doGetSimpleProductsById(match.params.id)
    setProductId(product.data.id)
    setIpiReadOnly(!!product.data.ncm)
    setFormValues({ ...product.data })
    setIsLoading(false)
  }, [doGetSimpleProductsById, match.params.id])

  useEffect(() => {
    if (match.params.id && categories && providers && ncms) {
      fetchProductData()
    } else {
      setIsLoading(false)
    }
  }, [categories, providers, ncms, fetchProductData, match.params.id])

  useEffect(() => {
    setIpiReadOnly(!!watchNcm)
    if (!!watchNcm && ncms.length > 0) {
      const ncm = ncms.find((ncm) => ncm.id === watchNcm)
      setValue('ipi', ncm.ipi)
    }
  }, [watchNcm]) // eslint-disable-line

  const onSubmit = async (data) => {
    try {
      await doRegisterSimpleProducts({ ...data, id: productId })
      toast.success('Produto cadastrado com sucesso')
      history.push('/simple-products')
    } catch {
      toast.error('Ocorreu um erro, tente novamente!')
    }
  }

  const calculateTotalCost = useCallback(() => {
    const { conversion, price, freight, ipi, st, icms_buy } = getValues()

    const vConversion = conversion ? parseFloat(conversion) : 1
    const vFreight = freight ? parseFloat(freight) : 0
    const vPrice = price ? parseFloat(price) : 0
    const vIpi = ipi ? parseFloat(ipi) : 0
    const vSt = st ? parseFloat(st) : 0
    const vIcmsBuy = icms_buy ? parseFloat(icms_buy) : 0

    const valueFreight = vPrice * (vFreight / 100)
    const valueIpi = (vPrice + valueFreight) * (vIpi / 100)
    const valueIcmsBuy = vPrice * (vIcmsBuy / 100)
    const valueSt = vPrice * (vSt / 100)

    // console.log('preço', vPrice, valueIpi,valueFreight , valueIcmsBuy, valueSt);

    //=IF(C6;(C4+C6)*B5;C4*B5)

    // const vCostWithoutFreight =
    //   roundDecimal(vPrice / coefficient(vIpi + vSt - vIcmsBuy)) * vConversion

    // setValue('total_cost', calculateMarginTotal(vFreight, vCostWithoutFreight))

    const vCostWithoutFreight = roundDecimal(
      vPrice - valueIpi + valueSt + valueFreight - valueIcmsBuy 
    ) * vConversion

    setValue('total_cost', vCostWithoutFreight)
  }, []) // eslint-disable-line

  const calculateSellPrices = useCallback(() => {
    const {
      total_cost,
      resale_margin,
      distributor_margin,
      producer_margin,
      icms_sell,
      pis_confins,
      expenses,
      commission,
      ipi_sell,
    } = getValues()

    const vTotalCost = total_cost ? parseFloat(total_cost) : 0
    const vResaleMargin = resale_margin ? parseFloat(resale_margin) : 0
    const vDistributorMargin = distributor_margin ? parseFloat(distributor_margin) : 0
    const vProducerMargin = producer_margin ? parseFloat(producer_margin) : 0
    const vIcmsSell = icms_sell ? parseFloat(icms_sell) : 0
    const vPisConfins = pis_confins ? parseFloat(pis_confins) : 0
    const vExpenses = expenses ? parseFloat(expenses) : 0
    const vCommission = commission ? parseFloat(commission) : 0
    const vIpiSell = ipi_sell ? parseFloat(ipi_sell) : 0

    // const totalCosts = roundDecimal(
    //   icms_sell + vIcmsSell + vPisConfins + vExpenses + vCommission
    // )

    // console.log('total custos comercialização:', vIcmsSell, vPisConfins, vCommission);
    // console.log('total custos comercialização:', vIcmsSell + vPisConfins + vCommission);
    // console.log('lucro desejado', vResaleMargin);
    // console.log('despesas fixas', vExpenses);
    // console.log('total porcentagem', vIcmsSell + vPisConfins + vCommission + vResaleMargin + vExpenses)

    const markupShell = roundDecimal(
      100 / (100 - (vIcmsSell + vPisConfins + vCommission + vResaleMargin + vExpenses + vIpiSell))
    )

    const markupDistributor = roundDecimal(
      100 /
        (100 - (vIcmsSell + vPisConfins + vCommission + vDistributorMargin + vExpenses + vIpiSell))
    )

    const markupProducer = roundDecimal(
      100 /
        (100 - (vIcmsSell + vPisConfins + vCommission + vProducerMargin + vExpenses + vIpiSell))
    )

    // console.log('custo', vTotalCost);
    // console.log('markup', markup);

    //console.log(vIcmsSell + vPisConfins + vExpenses + vCommission);

    const totalCostsShell = roundDecimal(vTotalCost * markupShell)
    const totalCostsDistributor = roundDecimal(vTotalCost * markupDistributor)
    const totalCostsProducer = roundDecimal(vTotalCost * markupProducer)

    // console.log(totalCosts);

    setValue('distributor_total', totalCostsDistributor)
    setValue('resale_total', totalCostsShell)
    setValue('producer_total', totalCostsProducer)
  }, []) // eslint-disable-line

  useEffect(() => {
    calculateTotalCost()
  }, [
    calculateTotalCost,
    watchConversion,
    watchPrice,
    watchFreight,
    watchIpi,
    watchSt,
    watchIcmsBuy,
  ])

  useEffect(() => {
    calculateSellPrices()
  }, [
    calculateSellPrices,
    watchTotalCost,
    watchResaleMargin,
    watchDistributorMargin,
    watchProducerMargin,
    watchIcmsSell,
    watchPisConfins,
    watchExpenses,
    watchCommission,
    watchIpiSell,
  ])

  return (
    <Container title="Cadastro de Produto Simples" className="simple-products-register">
      <Card>
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
            <Input
              label="Código"
              name="code"
              placeholder="Código do produto"
              col={2}
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.code}
            />
            <Input
              label="Código do fornecedor"
              name="provider_code"
              placeholder="Código do fornecedor"
              col={2}
              ref={register()}
              error={errors}
              defaultValue={formValues.provider_code}
            />
            <Select
              setValue={setValue}
              register={register}
              label="Fornecedor"
              name="provider"
              options={providers.map((provider) => ({
                value: provider.id,
                label: `${provider.nickname}`,
              }))}
              placeholder="Selecione um fornecedor"
              error={errors}
              defaultValue={formValues.provider}
              isClearable
            />
            <Select
              setValue={setValue}
              register={register}
              label="Categoria"
              name="category"
              options={categories.map((category) => ({
                value: category.id,
                label: category.description,
              }))}
              placeholder="Selecione uma categoria"
              error={errors}
              defaultValue={formValues.category}
              isClearable
            />
            <Select
              setValue={setValue}
              register={register}
              label="NCM"
              name="ncm"
              options={ncms.map((ncm) => ({
                value: ncm.id,
                label: `${ncm.code} - ${ncm.description}`,
              }))}
              placeholder="Selecione um NCM"
              error={errors}
              defaultValue={formValues.ncm}
              isClearable
            />
            <Divider withBorder />
            <Input
              label="Nome do produto"
              name="description"
              placeholder="Informe o nome do produto"
              col={8}
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.description}
            />
            <Input
              label="Preço"
              name="price"
              col={4}
              mask="money"
              error={errors}
              ref={register({ required: true })}
              control={control}
              defaultValue={formValues.price}
              setValue={setValue}
            />
            <Input
              label="Conversão"
              name="conversion"
              col={2}
              type="number"
              error={errors}
              ref={register({ required: true })}
              defaultValue={formValues.conversion}
            />
            <Select
              required
              setValue={setValue}
              register={register}
              col={2}
              label="Medida de conversão"
              name="measure"
              placeholder="Medida"
              options={[
                { value: 'PC', label: 'Peça (PC)' },
                { value: 'KG', label: 'Quilograma (KG)' },
                { value: 'MT', label: 'Metro (MT)' },
                { value: 'CJ', label: 'Conjunto (CJ)' },
                { value: 'UN', label: 'Unidade (UN)' },
                { value: 'HR', label: 'Hora (HR))' },
                { value: 'CX', label: 'Caixa (CX)' },
                { value: 'JG', label: 'Jogo (JG)' },
                { value: 'PR', label: 'Par (PR)' },
                { value: 'PT', label: 'Pacote (PT)' },
                { value: 'LT', label: 'Litro (LT)' },
              ]}
              rules={{ required: true }}
              error={errors}
              defaultValue={formValues.measure}
            />
            <InputWithSymbol
              label="Frete"
              name="freight"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.freight}
            />
            <InputWithSymbol
              label="IPI"
              name="ipi"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.ipi}
              readOnly={ipiReadOnly}
            />
            <InputWithSymbol
              label="S.T"
              name="st"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.st}
            />
            <InputWithSymbol
              label="ICMS(Compra)"
              name="icms_buy"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.icms_buy}
            />
            <Input
              label="Custo total"
              name="total_cost"
              col={6}
              mask="money"
              readOnly
              error={errors}
              control={control}
              ref={register({ required: true })}
              defaultValue={formValues.total_cost}
              setValue={setValue}
            />
            <Divider withBorder />
            <InputWithSymbol
              label="Margem Distribuidor"
              name="distributor_margin"
              col={4}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.distributor_margin}
            />
            <InputWithSymbol
              label="Margem Revenda"
              name="resale_margin"
              col={4}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.resale_margin}
            />
            <InputWithSymbol
              label="Margem Produtor"
              name="producer_margin"
              col={4}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.producer_margin}
            />
            <InputWithSymbol
              label="ICMS(Venda)"
              name="icms_sell"
              col={3}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.icms_sell}
            />
            <InputWithSymbol
              label="PIS/CONFINS"
              name="pis_confins"
              col={3}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.pis_confins}
            />
            <InputWithSymbol
              label="Despesas"
              name="expenses"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.expenses}
            />
            <InputWithSymbol
              label="Comissão"
              name="commission"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.commission}
            />
            <InputWithSymbol
              label="IPI"
              name="ipi_sell"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.ipi_sell}
            />
            <Divider withBorder />
            <Input
              label="Valor Distribuidor"
              name="distributor_total"
              col={4}
              mask="money"
              readOnly
              error={errors}
              control={control}
              ref={register({ required: true })}
              defaultValue={formValues.distributor_total}
              setValue={setValue}
            />
            <Input
              label="Valor Revenda"
              name="resale_total"
              col={4}
              mask="money"
              readOnly
              error={errors}
              control={control}
              ref={register({ required: true })}
              defaultValue={formValues.resale_total}
              setValue={setValue}
            />
            <Input
              label="Valor Produtor"
              name="producer_total"
              col={4}
              mask="money"
              readOnly
              error={errors}
              control={control}
              ref={register({ required: true })}
              defaultValue={formValues.producer_total}
              setValue={setValue}
            />
            <Divider withBorder />
            <div className="actions">
              <Button to="/simple-products" label="Cancelar" variation="btn-secondary" />
              <Button type="submit" label="Salvar" variation="btn-primary" />
            </div>
          </form>
        )}
      </Card>
    </Container>
  )
}

export default SimpleProductsRegister
