import React from 'react'
import InputMask from 'react-input-mask'

const InputWithSymbol = React.forwardRef(
  (
    {
      label = null,
      placeholder = '',
      name,
      type = 'text',
      col = '',
      mask = null,
      prefix = null,
      suffix = null,
      error,
      defaultValue,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={`form-group col${col && `-${col} ${error && error[name] ? 'is-invalid' : ''}`}`}
      >
        {label && <label htmlFor={`input-${name}`}>{label}</label>}
        <div className="input-group">
          {prefix && (
            <div className="input-group-prepend">
              <div className="input-group-text">{prefix}</div>
            </div>
          )}
          {!mask ? (
            <input
              name={name}
              type={type}
              className={`form-control ${error && error[name] ? 'is-invalid' : ''}`}
              id={`input-${name}`}
              placeholder={placeholder}
              defaultValue={defaultValue}
              ref={ref}
              {...rest}
            />
          ) : (
            <InputMask
              name={name}
              id={`input-${name}`}
              mask={mask}
              placeholder={placeholder}
              className={`form-control ${error && error[name] ? 'is-invalid' : ''}`}
              defaultValue={defaultValue}
              ref={ref}
              {...rest}
            />
          )}
          {suffix && (
            <div className="input-group-append">
              <div className="input-group-text">{suffix}</div>
            </div>
          )}
        </div>

        {error && error[name] && <div className="error">Campo obrigatório</div>}
      </div>
    )
  }
)

export default InputWithSymbol
