import { useCallback } from 'react'
import api from 'services/api'

const useGetCompoundProducts = () => {
  return useCallback(async (filters) => {
    let encodedFilters = ''
    if (filters) {
      encodedFilters = `?${Object.entries(filters)
        .map((e) => e.join('='))
        .join('&')}`
    }
    return api.get(`/compound-products/${encodedFilters}`)
  }, [])
}

export default useGetCompoundProducts
