import React from 'react'
import Title from 'components/shared/Title'

const Container = ({ children, title, action = null, className = '' }) => {
  return (
    <div className={`main-container ${className}`}>
      <div className="container">
        <div className="header mt-4">
          <Title>{title}</Title>
          {action && action}
        </div>
        <div className="d-flex mt-4 main-content">{children}</div>
      </div>
    </div>
  )
}

export default Container
