import React, { useState, useEffect, useCallback } from 'react'
import useGetEmployees from 'hooks/employees/useGetEmployees'
import useGetSimpleProducts from 'hooks/simpleProducts/useGetSimpleProducts'
import useGetCompoundProducts from 'hooks/compoundProducts/useGetCompoundProducts'
import Loader from 'components/shared/Loader'
import ReactSelect from 'react-select'

import './SearchParts.scss'

const SearchParts = ({ handleAddItem }) => {
  const doGetEmployees = useGetEmployees()
  const doGetSimpleProducts = useGetSimpleProducts()
  const doGetCompoundProducts = useGetCompoundProducts()

  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState([])

  const fetchInitialData = useCallback(async () => {
    const employees = await doGetEmployees().then((items) =>
      items.data.map((item) => ({ value: item.id, label: item.name, type: 'EMPLOYEE', item }))
    )
    const simpleProducts = await doGetSimpleProducts().then((items) =>
      items.data.map((item) => ({
        value: item.id,
        label: `(${item.code}) ${item.description}`,
        type: 'SIMPLE-PRODUCT',
        item,
      }))
    )
    const compoundProducts = await doGetCompoundProducts().then((items) =>
      items.data.map((item) => ({
        value: item.id,
        label: `(${item.code}) ${item.description}`,
        type: 'COMPOUND-PRODUCT',
        item,
      }))
    )

    setOptions([
      {
        label: 'Produtos Simples',
        options: simpleProducts,
      },
      {
        label: 'Produtos Compostos',
        options: compoundProducts,
      },
      {
        label: 'Funcionários',
        options: employees,
      },
    ])
    setIsLoading(false)
  }, [doGetEmployees, doGetCompoundProducts, doGetSimpleProducts])

  useEffect(() => {
    fetchInitialData()
  }, [fetchInitialData])

  const handleChange = (e) => {
    const { type, item, label } = e
    handleAddItem({ type, item, label })
  }

  return isLoading ? (
    <Loader />
  ) : (
    <div className="search-parts">
      <div className="form-group col">
        <ReactSelect
          options={options}
          classNamePrefix="select"
          isLoading={isLoading}
          isClearable
          isSearchable
          noOptionsMessage={() => <span>Nenhuma opção encontrada</span>}
          placeholder="Busque peças e funcionários que compoem a construção desse produto"
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

export default SearchParts
