import React, { useMemo } from 'react'
import { useForm } from 'react-hook-form'
import Input from 'components/shared/Input'
import Button from 'components/shared/Button'
import Select from 'components/shared/Select'
import { useData } from 'context/data'

const Filter = ({ handleFilters, initialValues }) => {
  const { categories } = useData()
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: useMemo(() => initialValues, [initialValues]),
  })

  const onSubmit = (data) => {
    handleFilters({ ...data, page: 1 })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        <Input ref={register} name="code" label="Código" col={2} />
        <Input ref={register} name="description" label="Nome do Produto" />
        <Select
          isClearable
          setValue={setValue}
          register={register}
          label="Categoria"
          name="category"
          options={categories.map((category) => ({
            value: category.id,
            label: category.description,
          }))}
          placeholder="Selecione uma categoria"
          error={errors}
          defaultValue={null}
        />
        <Button type="submit" label="Filtrar" col={1} fullWidth />
      </div>
    </form>
  )
}

export default Filter
