import { useCallback } from 'react'
import api from 'services/api'

const useRemoveCompoundProducts = () => {
  return useCallback(async (id) => {
    return api.delete(`/compound-products/${id}`)
  }, [])
}

export default useRemoveCompoundProducts
