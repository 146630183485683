import { useCallback } from 'react'
import api from 'services/api'

const useRemoveProviders = () => {
  return useCallback(async (id) => {
    return api.delete(`/providers/${id}`)
  }, [])
}

export default useRemoveProviders
