import { useCallback } from 'react'
import api from 'services/api'

const useGetProviders = () => {
  return useCallback(async (filters) => {
    let encodedFilters = ''
    if (filters) {
      encodedFilters = `?${Object.entries(filters)
        .map((e) => e.join('='))
        .join('&')}`
    }
    return api.get(`/providers/${encodedFilters}`).then((response) => {
      return response
    })
  }, [])
}

export default useGetProviders
