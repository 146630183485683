import React from 'react'
import CurrencyInput from 'react-currency-input'
import InputMask from 'react-input-mask'
import { Controller } from 'react-hook-form'

const Input = React.forwardRef(
  (
    {
      label = null,
      name,
      type = 'text',
      col = '',
      mask = null,
      error,
      control,
      defaultValue,
      setValue,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={`form-group col${col && `-${col} ${error && error[name] ? 'is-invalid' : ''}`}`}
      >
        {label && <label htmlFor={`input-${name}`}>{label}</label>}
        {!mask && (
          <input
            formNoValidate="formnovalidate"
            type={type}
            className={`form-control ${error && error[name] ? 'is-invalid' : ''}`}
            id={`input-${name}`}
            name={name}
            ref={ref}
            defaultValue={defaultValue}
            {...rest}
          />
        )}
        {mask === 'money' && (
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">R$</div>
            </div>
            <Controller
              rules={ref}
              id={`input-${name}`}
              name={name}
              control={control}
              defaultValue={defaultValue}
              render={({ onChange, value }) => (
                <CurrencyInput
                  className={`form-control ${error && error[name] ? 'is-invalid' : ''}`}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  allowNegative={false}
                  inputType="text"
                  value={value}
                  onChangeEvent={(e, maskedValue, floatValue) => onChange(floatValue)}
                  {...rest}
                />
              )}
            />
          </div>
        )}
        {mask && mask !== 'money' && (
          <InputMask
            id={`input-${name}`}
            mask={mask}
            className={`form-control ${error && error[name] ? 'is-invalid' : ''}`}
            name={name}
            inputRef={ref}
            defaultValue={defaultValue}
          />
        )}
        {error && error[name] && <div className="error">Campo obrigatório</div>}
      </div>
    )
  }
)

export default Input
