import { useCallback } from 'react'
import api from 'services/api'

const useGetEmployeesById = () => {
  return useCallback(async (id) => {
    return api.get(`/employees/single/${id}`)
  }, [])
}

export default useGetEmployeesById
