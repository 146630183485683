import { useCallback } from 'react'
import api from 'services/api'

const useGetSimpleProductsById = () => {
  return useCallback(async (id) => {
    return api.get(`/simple-products/single/${id}`)
  }, [])
}

export default useGetSimpleProductsById
