import React from 'react'
import { Link } from 'react-router-dom'

const Button = ({
  label,
  to = null,
  type = 'button',
  variation = 'btn-primary',
  col = '',
  icon = null,
  fullWidth = false,
  disabled = false,
  ...rest
}) => {
  return (
    <div className={`form-group col${col && `-${col}`} btn-theme`}>
      {to ? (
        <Link to={to} className={`btn ${variation}`}>
          {icon && icon} {label}
        </Link>
      ) : (
        <button
          disabled={disabled}
          type={type}
          className={`btn ${variation} ${fullWidth ? 'full-width' : ''}`}
          {...rest}
        >
          {icon && icon} {label}
        </button>
      )}
    </div>
  )
}

export default Button
