import React from 'react'
import { Switch, Route } from 'react-router-dom'

import SimpleProducts from 'pages/SimpleProducts'
import SimpleProductsRegister from 'pages/SimpleProductsRegister'
import Categories from 'pages/Categories'
import CategoriesRegister from 'pages/CategoriesRegister'
import Ncms from 'pages/Ncms'
import NcmsRegister from 'pages/NcmsRegister'
import Employees from 'pages/Employees'
import Providers from 'pages/Providers'
import ProvidersRegister from 'pages/ProvidersRegister'
import EmployeesRegister from 'pages/EmployeesRegister'
import CompoundProducts from 'pages/CompoundProducts'
import CompoundProductsRegister from 'pages/CompoundProductsRegister'

import SignIn from './pages/SignIn'
import PrivateRoute from './components/shared/PrivateRoute'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={SignIn} />
      <PrivateRoute exact={true} path="/simple-products" component={SimpleProducts} />
      <PrivateRoute
        exact={true}
        path="/simple-products/register/:id?"
        component={SimpleProductsRegister}
      />
      <PrivateRoute exact={true} path="/categories" component={Categories} />
      <PrivateRoute exact={true} path="/categories/register/:id?" component={CategoriesRegister} />

      <PrivateRoute exact={true} path="/ncms" component={Ncms} />
      <PrivateRoute exact={true} path="/ncms/register/:id?" component={NcmsRegister} />

      <PrivateRoute exact={true} path="/providers" component={Providers} />
      <PrivateRoute exact={true} path="/providers/register/:id?" component={ProvidersRegister} />

      <PrivateRoute exact={true} path="/employees" component={Employees} />
      <PrivateRoute exact={true} path="/employees/register/:id?" component={EmployeesRegister} />

      <PrivateRoute exact={true} path="/compound-products" component={CompoundProducts} />
      <PrivateRoute
        exact={true}
        path="/compound-products/register/:id?"
        component={CompoundProductsRegister}
      />

      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  )
}

export default Routes
