import React, { useState, useEffect, useCallback } from 'react'
import ReactSelect from 'react-select'
import { Controller } from 'react-hook-form'

const Select = React.forwardRef(
  (
    {
      label = null,
      name,
      isSearchable = true,
      isClearable = false,
      isDisabled = false,
      defaultValue,
      options,
      col = '',
      register = null,
      setValue,
      error,
      required,
      placeholder = 'Selecionar',
      control = false,
    },
    ref
  ) => {
    const [isLoading, setIsLoading] = useState(true)
    const [selectValue, setSelectValue] = useState(null)

    const fetchInitialData = useCallback(() => {
      if (register) {
        register(name, { required })
      }
      if (defaultValue) {
        setValue(name, defaultValue)
        setSelectValue(options.find((item) => item.value === defaultValue))
      }
      setIsLoading(false)
    }, []) // eslint-disable-line

    useEffect(() => {
      setValue(name, defaultValue)
      setSelectValue(options.find((item) => item.value === defaultValue))
    }, [defaultValue]) // eslint-disable-line

    useEffect(() => {
      fetchInitialData()
    }, [fetchInitialData])

    return (
      <div
        ref={ref}
        className={`form-group col${col && `-${col} ${error && error[name] ? 'is-invalid' : ''}`}`}
      >
        {label && <label htmlFor={`input-${name}`}>{label}</label>}
        {control ? (
          <Controller
            render={({ onChange, name, ref }) => (
              <ReactSelect
                ref={ref}
                options={options}
                className={`${error && error[name] ? 'is-invalid' : ''}`}
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isSearchable={isSearchable}
                noOptionsMessage={() => <span>Nenhuma opção encontrada</span>}
                value={selectValue}
                placeholder={placeholder}
                onChange={(e, { action }) => {
                  setSelectValue(e)
                  if (action === 'clear') {
                    onChange(null)
                  } else {
                    onChange(e.value)
                  }
                }}
              />
            )}
            control={control}
            name={name}
            defaultValue={selectValue}
          />
        ) : (
          <ReactSelect
            name={name}
            options={options}
            className={`${error && error[name] ? 'is-invalid' : ''}`}
            classNamePrefix="select"
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            noOptionsMessage={() => <span>Nenhuma opção encontrada</span>}
            value={selectValue}
            placeholder={placeholder}
            onChange={(e, { action }) => {
              setSelectValue(e)
              if (action === 'clear') {
                setValue(name, null)
              } else {
                setValue(name, e.value)
              }
            }}
          />
        )}

        {error && error[name] && <div className="error">Campo obrigatório</div>}
      </div>
    )
  }
)

export default Select
