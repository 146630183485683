import React from 'react'
import { FiTrash } from 'react-icons/all'

const RemoveButton = ({ ...props }) => {
  return (
    <button className="button-action button-remove" {...props}>
      <FiTrash />
    </button>
  )
}

export default RemoveButton
