import React, { useState, useEffect, useCallback } from 'react'
import { FiFileText, FiPlus } from 'react-icons/all'

import Container from 'components/template/Container'
import Table from 'components/shared/Table'
import EditButton from 'components/shared/Table/components/EditButton'
import RemoveButton from 'components/shared/Table/components/RemoveButton'
import Filter from './components/Filter'
import Button from 'components/shared/Button'

import useGetCompoundProducts from 'hooks/compoundProducts/useGetCompoundProducts'
import useRemoveCompoundProducts from 'hooks/compoundProducts/useRemoveCompoundProducts'

import { numberToReal } from 'utils/functions'
import Loader from 'components/shared/Loader'
import Modal from 'components/shared/Modal'
import { toast } from 'react-toastify'
import Paginator from 'components/shared/Paginator'
import useDownloadCompoundProducts from 'hooks/compoundProducts/useDownloadCompoundProducts'

const INITIAL_FILTER_VALUES = {
  code: '',
  provider_code: '',
  description: '',
  category: '',
  page: 1,
}

const CompoundProducts = () => {
  const doGetCompoundProducts = useGetCompoundProducts()
  const doDownloadCompoundProducts = useDownloadCompoundProducts()
  const doRemoveCompoundProducts = useRemoveCompoundProducts()

  const [generatingSpreadsheet, setGeneratingSpreadsheet] = useState(false)
  const [products, setProducts] = useState([])
  const [pagination, setPagination] = useState({
    totalItems: 0,
    lastPage: 0,
    currentPage: 0,
  })
  const [removeProduct, setRemoveProduct] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [queryParams, setQueryParams] = useState(INITIAL_FILTER_VALUES)

  const getProducts = useCallback(async () => {
    setIsLoading(true)
    doGetCompoundProducts({ ...queryParams })
      .then((result) => {
        const { data, total, lastPage, currentPage } = result.data
        setPagination({
          totalItems: total,
          lastPage: lastPage,
          currentPage: currentPage,
        })
        setProducts(data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [doGetCompoundProducts, queryParams])

  useEffect(() => {
    getProducts()
  }, [queryParams, getProducts])

  const handlePagination = (page) => {
    setQueryParams({ ...queryParams, page })
  }

  const handleRemoveProduct = async () => {
    if (removeProduct) {
      try {
        await doRemoveCompoundProducts(removeProduct.id)
        setProducts(products.filter((product) => product.id !== removeProduct.id))
        setRemoveProduct(null)
        toast.success('Produto removido com sucesso!')
      } catch {
        toast.error('Ocorreu um erro!')
      }
    }
  }

  const handleDownloadSpreadsheet = () => {
    if (!generatingSpreadsheet) {
      setGeneratingSpreadsheet(true)
      doDownloadCompoundProducts({ ...queryParams }).finally(() => {
        setGeneratingSpreadsheet(false)
      })
    }
  }

  return (
    <Container
      title={
        <div className="header-actions">
          <span>Produtos Compostos</span>
          <Button
            variation="btn-dark btn-sm"
            onClick={handleDownloadSpreadsheet}
            label={generatingSpreadsheet ? 'Aguarde... Gerando planilha' : 'Gerar planilha'}
            icon={<FiFileText />}
            col="col"
          />
        </div>
      }
      action={
        <Button
          to="/compound-products/register"
          label="Adicionar Produto"
          icon={<FiPlus />}
          col="col"
        />
      }
    >
      <Table
        header={<Filter initialValues={INITIAL_FILTER_VALUES} handleFilters={setQueryParams} />}
      >
        <thead>
          <tr>
            <th>Cód.</th>
            <th>Produto</th>
            <th>Categoria</th>
            <th className="text-right">Custo Total</th>
            <th className="text-right">Distribuidor</th>
            <th className="text-right">Revenda</th>
            <th className="text-right">Produtor</th>
            <th>Opções</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={9} className="loading">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {products.length > 0 ? (
                products.map((item) => (
                  <tr key={item.id}>
                    <td>{item.code}</td>
                    <td>
                      <b>{item.description}</b>
                    </td>
                    <td>
                      <span className="category">{item.category || '--'}</span>
                    </td>
                    <td className="nowrap text-right">{numberToReal(item.total_cost)}</td>
                    <td className="nowrap text-right">
                      <div className="totals">
                        <span>{numberToReal(item.distributor_total)}</span>
                        <small>Margem {item.distributor_margin}%</small>
                      </div>
                    </td>
                    <td className="nowrap text-right">
                      <div className="totals">
                        <span>{numberToReal(item.resale_total)}</span>
                        <small>Margem {item.resale_margin}%</small>
                      </div>
                    </td>
                    <td className="nowrap text-right">
                      <div className="totals">
                        <span>{numberToReal(item.producer_total)}</span>
                        <small>Margem {item.producer_margin}%</small>
                      </div>
                    </td>
                    <td>
                      <EditButton to={`/compound-products/register/${item.id}`} />
                      <RemoveButton onClick={() => setRemoveProduct(item)} />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="empty" colSpan={9}>
                    Nenhum produto encontrado
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </Table>
      <Paginator props={pagination} handlePagination={handlePagination} />
      {removeProduct && (
        <Modal
          title="Remover produto"
          handleCloseModal={() => setRemoveProduct(null)}
          footer={
            <div className="modal-actions">
              <button
                onClick={() => setRemoveProduct(null)}
                type="button"
                className="btn btn-secondary"
              >
                Cancelar
              </button>
              <button onClick={handleRemoveProduct} type="button" className="btn btn-danger">
                Remover
              </button>
            </div>
          }
        >
          <p style={{ textAlign: 'center' }}>
            Você está prestes a remover o produto <br /> <b>{removeProduct.description}.</b> <br />{' '}
            Deseja prosseguir?
          </p>
        </Modal>
      )}
    </Container>
  )
}

export default CompoundProducts
