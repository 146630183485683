import { useCallback } from 'react'
import api from 'services/api'

const useDownloadSimpleProducts = () => {
  return useCallback(async (filters) => {
    let encodedFilters = ''
    if (filters) {
      encodedFilters = `?${Object.entries(filters)
        .map((e) => e.join('='))
        .join('&')}`
    }
    return api
      .get(`/simple-products/download/${encodedFilters}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      })
      .then((response) => {
        let data = new Date(),
          day = data.getDate().toString(),
          dayF = day.length === 1 ? '0' + day : day,
          month = (data.getMonth() + 1).toString(),
          monthF = month.length === 1 ? '0' + month : month,
          yearF = data.getFullYear()
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Produtos-Simples-${yearF}-${monthF}-${dayF}.csv`
        link.click()
      })
  }, [])
}

export default useDownloadSimpleProducts
