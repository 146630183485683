import { useCallback } from 'react'
import api from 'services/api'

const useGetSimpleProducts = () => {
  return useCallback(async (filters) => {
    let encodedFilters = ''
    if (filters) {
      encodedFilters = `?${Object.entries(filters)
        .map((e) => e.join('='))
        .join('&')}`
    }
    return api.get(`/simple-products/${encodedFilters}`)
  }, [])
}

export default useGetSimpleProducts
