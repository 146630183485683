import React from 'react'

const Table = ({ children, header }) => {
  return (
    <div className="table-content">
      <div className="table-theme">
        <div className="card">
          {header && <div className="card-header">{header}</div>}
          <table className="print">{children}</table>
        </div>
      </div>
    </div>
  )
}

export default Table
