import React, { useEffect, useState, useCallback } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import Container from 'components/template/Container'
import Card from 'components/shared/Card'
import Input from 'components/shared/Input'
import InputWithSymbol from 'components/shared/InputWithSymbol'
import Select from 'components/shared/Select'
import Divider from 'components/shared/Divider'
import Button from 'components/shared/Button'
import useRegisterCompoundProducts from 'hooks/compoundProducts/useRegisterCompoundProducts'
import useGetCompoundProductsById from 'hooks/compoundProducts/useGetCompoundProductsById'

import { useData } from 'context/data'
import { toast } from 'react-toastify'
import Loader from 'components/shared/Loader'
import SearchParts from './components/SearchParts'
import ProductPart from './components/ProductPart'
import Table from 'components/shared/Table'
import { roundDecimal, round } from 'utils/functions'

const CompoundProductsRegister = ({ history, match }) => {
  const [formValues, setFormValues] = useState({ parts: [] })
  const [isLoading, setIsLoading] = useState(true)
  const [productId, setProductId] = useState(null)
  const [removeParts, setRemoveParts] = useState([])

  const { categories, providers, ncms } = useData()

  const doGetCompoundProductsById = useGetCompoundProductsById()
  const doRegisterCompoundProducts = useRegisterCompoundProducts()

  const { register, control, handleSubmit, errors, watch, setValue, getValues, reset } = useForm()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'parts',
    shouldUnregister: true,
    keyName: 'partsFields',
  })

  const watchTotalCost = watch('total_cost')
  const watchResaleMargin = watch('resale_margin')
  const watchIpiSell = watch('ipi_sell')
  const watchDistributorMargin = watch('distributor_margin')
  const watchProducerMargin = watch('producer_margin')
  const watchIcmsSell = watch('icms_sell')
  const watchPisConfins = watch('pis_confins')
  const watchExpenses = watch('expenses')
  const watchCommission = watch('commission')

  const fetchProductData = useCallback(async () => {
    const product = await doGetCompoundProductsById(match.params.id)
    setProductId(product.data.id)
    reset(product.data)
    setFormValues({ ...product.data })
    setIsLoading(false)
  }, [doGetCompoundProductsById, match.params.id]) // eslint-disable-line

  useEffect(() => {
    if (match.params.id && categories && providers && ncms) {
      fetchProductData()
    } else {
      setIsLoading(false)
    }
  }, [categories, providers, ncms, match.params.id, fetchProductData])

  const onSubmit = async (data) => {
    try {
      await doRegisterCompoundProducts({ ...data, id: productId, removeParts })
      toast.success('Produto cadastrado com sucesso')
      history.push('/compound-products')
    } catch {
      toast.error('Ocorreu um erro, tente novamente!')
    }
  }

  const handleAddItem = (e) => {
    const { type, item, label } = e
    const nextOrder =
      fields.length > 0
        ? fields.reduce((max, current) =>
            parseInt(max.item_order) > parseInt(current.item_order) ? max : current
          )
        : { item_order: 0 }

    append({
      id: null,
      item_order: parseInt(nextOrder.item_order) + 1,
      code: item.code,
      simple_product_id: type === 'SIMPLE-PRODUCT' ? item.id : null,
      compound_product_id: type === 'COMPOUND-PRODUCT' ? item.id : null,
      employee_id: type === 'EMPLOYEE' ? item.id : null,
      description: label,
      measure: item.measure,
      total_cost: item.total_cost,
      item_type: type,
      operator: 'NONE',
      unity: 1,
      final_cost: item.total_cost,
    })
  }
  const calculateTotalCost = useCallback(() => {
    const parts = watch('parts')
    const sum = (t, v) => t + v
    const sumOfCost =
      parts && parts.length > 0
        ? parts.map((item) => round(item.final_cost, 2) || 0).reduce(sum)
        : 0
    setValue('total_cost', sumOfCost)
  }, []) // eslint-disable-line

  const calculateSellPrices = useCallback(() => {
    const {
      total_cost,
      resale_margin,
      distributor_margin,
      producer_margin,
      icms_sell,
      pis_confins,
      expenses,
      commission,
      ipi_sell,
    } = getValues()

    const vTotalCost = total_cost ? parseFloat(total_cost) : 0
    const vResaleMargin = resale_margin ? parseFloat(resale_margin) : 0
    const vDistributorMargin = distributor_margin ? parseFloat(distributor_margin) : 0
    const vProducerMargin = producer_margin ? parseFloat(producer_margin) : 0
    const vIcmsSell = icms_sell ? parseFloat(icms_sell) : 0
    const vPisConfins = pis_confins ? parseFloat(pis_confins) : 0
    const vExpenses = expenses ? parseFloat(expenses) : 0
    const vCommission = commission ? parseFloat(commission) : 0
    const vIpiSell = ipi_sell ? parseFloat(ipi_sell) : 0

    // const totalCosts = roundDecimal(
    //   icms_sell + vIcmsSell + vPisConfins + vExpenses + vCommission
    // )

    // console.log('total custos comercialização:', vIcmsSell, vPisConfins, vCommission);
    // console.log('total custos comercialização:', vIcmsSell + vPisConfins + vCommission);
    // console.log('lucro desejado', vResaleMargin);
    // console.log('despesas fixas', vExpenses);
    // console.log('total porcentagem', vIcmsSell + vPisConfins + vCommission + vResaleMargin + vExpenses)

    const markupShell = roundDecimal(
      100 / (100 - (vIcmsSell + vPisConfins + vCommission + vResaleMargin + vExpenses + vIpiSell))
    )

    const markupDistributor = roundDecimal(
      100 /
        (100 - (vIcmsSell + vPisConfins + vCommission + vDistributorMargin + vExpenses + vIpiSell))
    )

    const markupProducer = roundDecimal(
      100 /
        (100 - (vIcmsSell + vPisConfins + vCommission + vProducerMargin + vExpenses + vIpiSell))
    )

    // console.log('custo', vTotalCost);
    // console.log('markup', markup);

    //console.log(vIcmsSell + vPisConfins + vExpenses + vCommission);

    const totalCostsShell = roundDecimal(vTotalCost * markupShell)
    const totalCostsDistributor = roundDecimal(vTotalCost * markupDistributor)
    const totalCostsProducer = roundDecimal(vTotalCost * markupProducer)

    // console.log(totalCosts);

    setValue('distributor_total', totalCostsDistributor)
    setValue('resale_total', totalCostsShell)
    setValue('producer_total', totalCostsProducer)
  }, []) // eslint-disable-line

  useEffect(() => {
    calculateSellPrices()
  }, [
    calculateSellPrices,
    watchTotalCost,
    watchResaleMargin,
    watchDistributorMargin,
    watchProducerMargin,
    watchIcmsSell,
    watchPisConfins,
    watchExpenses,
    watchCommission,
    watchIpiSell,
  ])

  const handleRemovePart = (item) => {
    if (item.id) {
      const parts = removeParts
      parts.push(item.id)
      setRemoveParts(parts)
    }
  }

  return (
    <Container title="Cadastro de Produto Composto" className="compound-products-register">
      <Card>
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" noValidate>
            <Input
              label="Código"
              name="code"
              placeholder="Código do produto"
              col={2}
              ref={register({ required: true })}
              error={errors}
            />
            <Input
              label="Nome do produto"
              name="description"
              placeholder="Informe o nome do produto"
              col={7}
              ref={register({ required: true })}
              error={errors}
            />
            <Select
              setValue={setValue}
              register={register}
              label="Categoria"
              name="category"
              options={categories.map((category) => ({
                value: category.id,
                label: category.description,
              }))}
              placeholder="Selecione uma categoria"
              error={errors}
              defaultValue={formValues.category}
              isClearable
            />
            <Divider title="Itens que compoem o produto" />
            <SearchParts handleAddItem={handleAddItem} />
            <Table>
              <thead>
                <tr>
                  <th className="small">#</th>
                  <th className="small">Cód.</th>
                  <th>Descrição</th>
                  <th className="measure">Medida</th>
                  <th className="total_cost">Custo Total</th>
                  <th className="operator">Conversão</th>
                  <th className="unity">Unidade</th>
                  <th className="final_cost">Custo Final</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {fields.length > 0 ? (
                  fields.map((item, index) => (
                    <ProductPart
                      calculateTotalCost={calculateTotalCost}
                      control={control}
                      key={`part-${index}`}
                      index={index}
                      item={item}
                      setValue={setValue}
                      watch={watch}
                      register={register}
                      handleRemoveItem={handleRemovePart}
                      remove={remove}
                    />
                  ))
                ) : (
                  <tr>
                    <td className="empty" colSpan={8}>
                      <div className="empty">Esse produto não possui nenhum item cadastrado</div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <Divider />
            <Input
              label="Valor total"
              name="total_cost"
              col={6}
              mask="money"
              readOnly
              error={errors}
              control={control}
              ref={register({ required: true })}
              defaultValue={formValues.total_cost}
              setValue={setValue}
            />

            <Divider />
            <InputWithSymbol
              label="Margem Distribuidor"
              name="distributor_margin"
              col={4}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.distributor_margin}
            />
            <InputWithSymbol
              label="Margem Revenda"
              name="resale_margin"
              col={4}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.resale_margin}
            />
            <InputWithSymbol
              label="Margem Produtor"
              name="producer_margin"
              col={4}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.producer_margin}
            />
            <InputWithSymbol
              label="ICMS(Venda)"
              name="icms_sell"
              col={3}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.icms_sell}
            />
            <InputWithSymbol
              label="PIS/CONFINS"
              name="pis_confins"
              col={3}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.pis_confins}
            />
            <InputWithSymbol
              label="Despesas"
              name="expenses"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.expenses}
            />
            <InputWithSymbol
              label="Comissão"
              name="commission"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.commission}
            />
            <InputWithSymbol
              label="IPI"
              name="ipi_sell"
              col={2}
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.ipi_sell}
            />
            <Divider withBorder />
            <Input
              label="Valor Distribuidor"
              name="distributor_total"
              col={4}
              mask="money"
              readOnly
              error={errors}
              control={control}
              ref={register({ required: true })}
              defaultValue={formValues.distributor_total}
              setValue={setValue}
            />
            <Input
              label="Valor Revenda"
              name="resale_total"
              col={4}
              mask="money"
              readOnly
              error={errors}
              control={control}
              ref={register({ required: true })}
              defaultValue={formValues.resale_total}
              setValue={setValue}
            />
            <Input
              label="Valor Produtor"
              name="producer_total"
              col={4}
              mask="money"
              readOnly
              error={errors}
              control={control}
              ref={register({ required: true })}
              defaultValue={formValues.producer_total}
              setValue={setValue}
            />
            <Divider withBorder />
            <div className="actions">
              <Button to="/compound-products" label="Cancelar" variation="btn-secondary" />
              <Button type="submit" label="Salvar" variation="btn-primary" />
            </div>
          </form>
        )}
      </Card>
    </Container>
  )
}

export default CompoundProductsRegister
