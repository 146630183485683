import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import Container from 'components/template/Container'
import Card from 'components/shared/Card'
import Input from 'components/shared/Input'
import InputWithSymbol from 'components/shared/InputWithSymbol'
import Divider from 'components/shared/Divider'
import Button from 'components/shared/Button'
import useRegisterNcms from 'hooks/ncms/useRegisterNcms'
import useGetNcmsById from 'hooks/ncms/useGetNcmsById'

import { toast } from 'react-toastify'
import Loader from 'components/shared/Loader'
import { useData } from 'context/data'
import useGetNcms from 'hooks/ncms/useGetNcms'

const NcmsRegister = ({ history, match }) => {
  const { setNcms } = useData()

  const doGetNcms = useGetNcms()
  const [formValues, setFormValues] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [itemId, setItemId] = useState(null)

  const doGetNcmsById = useGetNcmsById()
  const doRegisterNcms = useRegisterNcms()

  const { register, handleSubmit, errors } = useForm({
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => formValues, [formValues]),
  })

  const fetchProductData = useCallback(async () => {
    const item = await doGetNcmsById(match.params.id)
    setItemId(item.data.id)
    setFormValues({ ...item.data })
    setIsLoading(false)
  }, [doGetNcmsById, match.params.id])

  useEffect(() => {
    if (match.params.id) {
      fetchProductData()
    } else {
      setIsLoading(false)
    }
  }, [fetchProductData, match.params.id])

  const onSubmit = async (data) => {
    try {
      await doRegisterNcms({ ...data, id: itemId })
      await doGetNcms().then((result) => setNcms(result.data))
      toast.success('Categoria cadastrada com sucesso')

      history.push('/ncms')
    } catch {
      toast.error('Ocorreu um erro, tente novamente!')
    }
  }

  return (
    <Container title="Cadastro de Categorias">
      <Card>
        {isLoading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Input
              label="NCM"
              name="code"
              placeholder="Informe código NCM"
              ref={register({ required: true })}
              error={errors}
              col={2}
              defaultValue={formValues.code}
            />
            <InputWithSymbol
              label="IPI"
              name="ipi"
              type="number"
              suffix="%"
              ref={register({ required: true })}
              error={errors}
              col={2}
              defaultValue={formValues.ipi}
              step="0.01"
            />
            <Input
              label="Descrição"
              name="description"
              placeholder="Informe a descrição do NCM"
              ref={register({ required: true })}
              error={errors}
              defaultValue={formValues.description}
            />
            <Divider withBorder />
            <div className="actions">
              <Button to="/ncms" label="Cancelar" variation="btn-secondary" />
              <Button type="submit" label="Salvar" variation="btn-primary" />
            </div>
          </form>
        )}
      </Card>
    </Container>
  )
}

export default NcmsRegister
