import api from './api'

export const TOKEN_KEY = '@Gelmeza:token'
export const DATA_KEY = '@Gelmeza:user'
export const isAuthenticated = async () => {
  const token = localStorage.getItem(TOKEN_KEY) || null
  if (!token) return false
  try {
    await api.post('/auth/validate-token', { token })
    return true
  } catch (err) {
    logout()
  }

  return false
}
export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const login = (data) => {
  const { name, email, token } = data
  localStorage.setItem(TOKEN_KEY, token)
  localStorage.setItem(DATA_KEY, JSON.stringify({ name, email }))
}
export const logout = (history) => {
  localStorage.removeItem(DATA_KEY)
  localStorage.removeItem(TOKEN_KEY)
  history.push('/')
}
