import React, { useEffect, memo } from 'react'
import Input from 'components/shared/Input'
import Select from 'components/shared/Select'
import { numberToReal, round } from 'utils/functions'
import RemoveButton from 'components/shared/Table/components/RemoveButton'

const ProductPart = ({
  item,
  index,
  setValue,
  watch,
  register,
  control,
  calculateTotalCost,
  handleRemoveItem,
  remove,
}) => {
  const operatorField = watch(`parts[${index}].operator`)
  const unityField = watch(`parts[${index}].unity`)

  useEffect(() => {
    Object.entries(item).map((field) => {
      const fieldName = field[0]
      let fieldValue = field[1]

      if (fieldName !== 'final_cost') {
        if (fieldName === 'total_cost') {
          fieldValue = parseFloat(fieldValue)
        } else if (fieldName === 'unity' || fieldName === 'item_order') {
          fieldValue = parseInt(fieldValue)
        }
        setValue(`parts[${index}].${fieldName}`, fieldValue)
      }
      return null
    })
  }, [item]) // eslint-disable-line

  const fetchData = () => {
    const operator = watch(`parts[${index}].operator`)
    const unity = watch(`parts[${index}].unity`)
    const total_cost = parseFloat(watch(`parts[${index}].total_cost`))
    let cost = 0
    if (operator === 'DIVIDE') {
      cost = total_cost / unity
    } else if (operator === 'MULTIPLY') {
      cost = total_cost * unity
    } else {
      cost = total_cost
    }

    setValue(`parts[${index}].final_cost`, round(cost, 2))
    calculateTotalCost()
  }

  useEffect(() => {
    fetchData()
  }, [operatorField, unityField, item]) // eslint-disable-line

  const handleRemove = () => {
    handleRemoveItem(item)
    remove(index)
    calculateTotalCost()
  }

  return (
    <tr>
      <td>#{item.item_order}</td>
      <td>
        <input type="hidden" ref={register()} name={`parts[${index}].id`} defaultValue={item.id} />
        <input
          type="hidden"
          ref={register()}
          name={`parts[${index}].item_order`}
          defaultValue={item.item_order}
        />
        <input
          type="hidden"
          ref={register()}
          name={`parts[${index}].simple_product_id`}
          defaultValue={item.simple_product_id}
        />
        <input
          type="hidden"
          ref={register()}
          name={`parts[${index}].compound_product_id`}
          defaultValue={item.compound_product_id}
        />
        <input
          type="hidden"
          ref={register()}
          name={`parts[${index}].employee_id`}
          defaultValue={item.employee_id}
        />
        <input
          type="hidden"
          ref={register()}
          name={`parts[${index}].item_type`}
          defaultValue={item.item_type}
        />
        <input
          type="hidden"
          ref={register()}
          name={`parts[${index}].description`}
          defaultValue={item.description}
        />
        <input
          type="hidden"
          ref={register()}
          name={`parts[${index}].measure`}
          defaultValue={item.measure}
        />
        <input
          type="hidden"
          ref={register()}
          name={`parts[${index}].code`}
          defaultValue={item.code}
        />
        <input
          type="hidden"
          ref={register()}
          name={`parts[${index}].total_cost`}
          defaultValue={item.total_cost}
        />
        {item.code}
      </td>
      <td>
        <span className="description">{item.description}</span>
      </td>
      <td>{item.measure}</td>
      <td>{numberToReal(item.total_cost)}</td>
      <td>
        <Select
          control={control}
          setValue={setValue}
          name={`parts[${index}].operator`}
          options={[
            { label: 'Nenhum', value: 'NONE' },
            {
              label: 'Dividir',
              value: 'DIVIDE',
            },
            { label: 'Multiplicar', value: 'MULTIPLY' },
          ]}
          defaultValue={item.operator}
        />
      </td>
      <td>
        <Input
          ref={register()}
          name={`parts[${index}].unity`}
          type="number"
          defaultValue={item.unity}
          onChange={(e) => setValue(`parts[${index}].unity`, e.target.value)}
        />
      </td>
      <td>
        <Input
          control={control}
          readOnly
          mask="money"
          ref={register()}
          name={`parts[${index}].final_cost`}
          defaultValue={item.final_cost}
        />
      </td>
      <td>
        <RemoveButton onClick={handleRemove} type="button" />
      </td>
    </tr>
  )
}

export default memo(ProductPart)
