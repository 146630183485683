import React, { useState, useEffect } from 'react'

const Paginator = ({ props, handlePagination }) => {
  const { totalItems, lastPage, currentPage } = props
  const [pages, setPages] = useState([])
  const paginationSize = 10

  const handlePaginator = () => {
    if (parseInt(lastPage) > 0) {
      let startPage, endPage

      if (lastPage <= paginationSize) {
        startPage = 1
        endPage = lastPage
      } else {
        const halfPagination = Math.round(paginationSize / 2)
        if (currentPage <= halfPagination + 1) {
          startPage = 1
          endPage = paginationSize
        } else if (currentPage + (halfPagination - 1) >= lastPage) {
          startPage = lastPage - (paginationSize - 1)
          endPage = lastPage
        } else {
          startPage = currentPage - halfPagination
          endPage = currentPage + (halfPagination - 1)
        }
      }

      const pgs = Array(endPage + 1 - startPage)
        .fill(0)
        .map((_, i) => startPage + i)
      setPages(pgs)
    }
  }

  useEffect(() => {
    handlePaginator()
  }, [lastPage, currentPage]) // eslint-disable-line

  return (
    totalItems > 0 && (
      <div className="paginator">
        <ul className="pagination">
          <li className={currentPage === 1 ? 'disabled' : ''}>
            <button disabled={currentPage === 1} type="button" onClick={() => handlePagination(1)}>
              Primeira página
            </button>
          </li>
          <li className={currentPage === 1 ? 'disabled' : ''}>
            <button
              disabled={currentPage === 1}
              type="button"
              onClick={() => handlePagination(currentPage - 1)}
            >
              Anterior
            </button>
          </li>
          {pages.length > 0 &&
            pages.map((page, index) => (
              <li key={`pagination-${index}`} className={currentPage === page ? 'current' : ''}>
                <button
                  disabled={currentPage === page}
                  type="button"
                  onClick={() => handlePagination(page)}
                >
                  {page}
                </button>
              </li>
            ))}
          <li className={currentPage === lastPage ? 'disabled' : ''}>
            <button
              disabled={currentPage === lastPage}
              type="button"
              onClick={() => handlePagination(currentPage + 1)}
            >
              Próxima
            </button>
          </li>
          <li className={currentPage === lastPage ? 'disabled' : ''}>
            <button
              disabled={currentPage === lastPage}
              type="button"
              onClick={() => handlePagination(lastPage)}
            >
              Última página
            </button>
          </li>
        </ul>
      </div>
    )
  )
}

export default Paginator
